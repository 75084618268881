import _ from "lodash";
import { path } from "../../api";
import { restClient } from "../../utils/restClient";
import * as actions from "../../utils/actions";

const initialState = {
  socialNetworks: [],
  whatsapp: null,
  cryptapi: null,
  banks: [],
  banksAccounts: [],
  privacyPolicies: "",
  termsAndConditions: "",
  welcomePage: "",
  loading: false,
  countries: [],
};

export default {
  namespaced: true,
  name: "systemConfig",
  state: _.cloneDeep(initialState),
  mutations: {
    [actions.GET_SOCIAL_NETWORKS](state, payload = []) {
      state.socialNetworks = payload;
    },
    [actions.GET_WHATSAPP](state, payload = null) {
      state.whatsapp = payload;
    },
    [actions.GET_CRYPTAPI_CONFIG](state, payload = null) {
      state.cryptapi = payload;
    },
    [actions.GET_BANKS](state, payload = null) {
      state.banks = payload;
    },
    [actions.GET_COUNTRIES](state, payload = []) {
      state.countries = payload;
    },
    [actions.GET_BANKS_ACCOUNTS](state, payload = null) {
      state.banksAccounts = payload;
    },
    [actions.GET_PRIVACY_POLICIES_TERMS_CONDITIONS](state, payload = {}) {
      state.privacyPolicies = payload?.privacyPolicies || "";
      state.termsAndConditions = payload?.termsAndConditions || "";
    },
    [actions.GET_WELCOME_PAGE](state, payload = null) {
      state.welcomePage = payload;
    },
  },
  actions: {
    async fetchSmptpConfig() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.smtp.base,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async saveSmptpConfig(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            payload.id
              ? path.admin.systemConfig.smtp.single(payload.id)
              : path.admin.systemConfig.smtp.base,
            payload.id ? "PUT" : "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchCraueConfig() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.base,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchPoliciesTerms() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.policiesAndTerms,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchWelcomePage() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.welcomePage,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchWelcomePageHTML() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.welcomePageHTML,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchCreditCardPaymentClients() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.creditCardPaymentClients,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchDepositAccounts() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.depositAccounts,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async saveCraueConfig(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.base,
            "PUT",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getCraueConfigByName(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.single(payload),
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getCraueConfigByNameClient(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.single(payload),
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchSocialNetworksConfig({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.socialNetworks,
            "GET"
          );
          commit(actions.GET_SOCIAL_NETWORKS, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_SOCIAL_NETWORKS, []);
          reject(error);
        }
      });
    },
    async fetchWhatsappConfig({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.whatsapp,
            "GET"
          );
          commit(actions.GET_WHATSAPP, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_WHATSAPP, null);
          reject(error);
        }
      });
    },
    async fetchCryptapiConfig({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.cryptapi,
            "GET"
          );
          commit(actions.GET_CRYPTAPI_CONFIG, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_CRYPTAPI_CONFIG, null);
          reject(error);
        }
      });
    },
    async fetchBanks({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.banks,
            "GET"
          );
          commit(actions.GET_BANKS, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_BANKS, null);
          reject(error);
        }
      });
    },
    async fetchAdminBanks({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.banks,
            "GET"
          );
          commit(actions.GET_BANKS, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_BANKS, null);
          reject(error);
        }
      });
    },
    async fetchBanksAccounts({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.banksAccounts,
            "GET"
          );
          commit(actions.GET_BANKS_ACCOUNTS, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_BANKS_ACCOUNTS, null);
          reject(error);
        }
      });
    },
    async getPrivacyPoliciesTermsConditions({ commit, state }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          state.loading = true;
          const { data = [], ...rest } = await restClient(
            path.client.systemConfig.craueConfig.policiesAndTerms,
            "GET"
          );
          commit(actions.GET_PRIVACY_POLICIES_TERMS_CONDITIONS, {
            privacyPolicies:
              data?.find((item) => item.name === "privacy_policies")?.value ||
              "",
            termsAndConditions:
              data?.find((item) => item.name === "terms_and_conditions")
                ?.value || "",
          });
          resolve({
            ...rest,
            data,
          });
        } catch (error) {
          commit(actions.GET_PRIVACY_POLICIES_TERMS_CONDITIONS);
          reject(error);
        } finally {
          state.loading = false;
        }
      });
    },
    async getWelcomePage({ commit, state }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          state.loading = true;
          const { data, ...rest } = await restClient(
            path.client.systemConfig.craueConfig.welcomePage,
            "GET"
          );
          commit(actions.GET_WELCOME_PAGE, {
            welcomePage: data,
          });
          resolve({
            ...rest,
            data,
          });
        } catch (error) {
          commit(actions.GET_WELCOME_PAGE);
          reject(error);
        } finally {
          state.loading = false;
        }
      });
    },
    async getCreditCardPaymentClient() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.creditCardPaymentClient,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async getCreditCardPaymentClientLinks() {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.systemConfig.craueConfig.creditCardPaymentClientLinks,
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchAdminCountries({ commit }) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.systemConfig.craueConfig.countries,
            "GET"
          );
          commit(actions.GET_COUNTRIES, response.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_COUNTRIES, null);
          reject(error);
        }
      });
    },
  },
  getters: {
    getCryptApiConfig(state) {
      return state.cryptapi;
    },
    getPrivacyPolicies(state) {
      return state.privacyPolicies;
    },
    getTermsAndConditions(state) {
      return state.termsAndConditions;
    },
    getIsLoading(state) {
      return state.loading || false;
    },
  },
};
