import _ from "lodash";
import { path } from "../../api";
import { restClient } from "../../utils/restClient";

const initialState = {};

export default {
  namespaced: true,
  name: "buy",
  state: _.cloneDeep(initialState),
  actions: {
    async fetchLotteries(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.lotteries.base,
            "GET",
            null,
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async processTicket(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.orders.buy,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
