let basePath = process.env.VUE_APP_API_URL;
export const path = {
  admin: {
    login: `${basePath}/admin/login`,
    loggedUser: `${basePath}/admin/logged-user`,
    logout: `${basePath}/admin/logout`,
    refreshToken: `${basePath}/admin/refresh-token`,
    resetPassword: `${basePath}/admin/reset-password`,
    changePassword: `${basePath}/admin/change-password`,
    shortUrl: {
      base: `${basePath}/admin/short-urls`,
      single: (uuid) => `${basePath}/admin/short-urls/${uuid}`,
    },
    admins: {
      base: `${basePath}/admin/admins`,
      single: (id) => `${basePath}/admin/admins/${id}`,
      roles: `${basePath}/admin/admins/roles`,
    },
    users: {
      base: `${basePath}/admin/users`,
      single: (id) => `${basePath}/admin/users/${id}`,
      roles: `${basePath}/admin/users/roles`,
    },
    provinces: {
      base: `${basePath}/admin/provinces`,
    },
    lotteries: {
      base: `${basePath}/admin/lotteries`,
      single: (id) => `${basePath}/admin/lotteries/${id}`,
    },
    raffles: {
      base: `${basePath}/admin/raffles`,
      confirm: `${basePath}/admin/raffles/confirm`,
      single: (id) => `${basePath}/admin/raffles/${id}`,
      singleByDate: (date) => `${basePath}/admin/raffles/date/${date}`,
    },
    blocks: {
      base: `${basePath}/admin/blocks`,
      single: (id) => `${basePath}/admin/blocks/${id}`,
    },
    transactions: {
      base: `${basePath}/admin/transactions`,
      types: `${basePath}/admin/transactions/types`,
      origins: `${basePath}/admin/transactions/origins`,
      single: (id) => `${basePath}/admin/transactions/${id}`,
      reject: (id) => `${basePath}/admin/transactions/${id}/reject`,
      approve: (id) => `${basePath}/admin/transactions/${id}/approve`,
      paywallLink: `${basePath}/admin/transactions/paywall-link`,
    },
    commissions: {
      report: `${basePath}/admin/commissions/report`,
      paid: `${basePath}/admin/commissions/paid`,
    },
    orders: {
      base: `${basePath}/admin/orders`,
      single: (id) => `${basePath}/admin/orders/${id}`,
    },
    systemConfig: {
      smtp: {
        base: `${basePath}/admin/smtp-configuration`,
        single: (id) => `${basePath}/admin/smtp-configuration/${id}`,
      },
      craueConfig: {
        base: `${basePath}/admin/craue-configuration`,
        single: (name) => `${basePath}/admin/craue-configuration/${name}`,
        banksAccounts: `${basePath}/admin/craue-configuration/banks-accounts`,
        banks: `${basePath}/admin/craue-configuration/banks`,
        countries: `${basePath}/admin/craue-configuration/countries`,
        policiesAndTerms: `${basePath}/admin/craue-configuration/policies-terms`,
        welcomePage: `${basePath}/admin/craue-configuration/welcome-page`,
        creditCardPaymentClients: `${basePath}/admin/craue-configuration/credit-card-payment-clients`,
        depositAccounts: `${basePath}/admin/craue-configuration/deposit-accounts`,
      },
    },
    commissionProfiles: {
      base: `${basePath}/admin/commission-profiles`,
      single: (id) => `${basePath}/admin/commission-profiles/${id}`,
    },
    transactionProfiles: {
      base: `${basePath}/admin/transaction-profiles`,
      single: (id) => `${basePath}/admin/transaction-profiles/${id}`,
    },
    balanceMovements: {
      base: `${basePath}/admin/balance-movements`,
    },
    reports: {
      monitoring: `${basePath}/admin/reports/monitoring`,
    },
    lotteryClosingSchedule: {
      base: `${basePath}/admin/lotteries-closing-schedules`,
      single: (id) => `${basePath}/admin/lotteries-closing-schedules/${id}`,
    },
  },
  client: {
    validateRecaptcha: `${basePath}/validate-recaptcha`,
    verifyPhone: `${basePath}/verify-phone`,
    login: `${basePath}/login`,
    loggedUser: `${basePath}/logged-user`,
    balance: `${basePath}/balance`,
    logout: `${basePath}/logout`,
    refreshToken: `${basePath}/refresh-token`,
    requestPasswordReset: `${basePath}/request-password-reset`,
    myReferrals: `${basePath}/my-referrals`,
    commissionProfile: `${basePath}/commission-profile`,
    commissionsPending: `${basePath}/commissions/pending`,
    accountByPhone: (phone) => `${basePath}/account-by-phone/${phone}`,
    provinces: {
      base: `${basePath}/provinces`,
    },
    raffles: {
      base: (date) => `${basePath}/raffles/${date}`,
      lottery: (date, lottery) => `${basePath}/raffles/${date}/${lottery}`,
    },
    register: `${basePath}/register`,
    verifyAccount: (code) => `${basePath}/verify-account/${code}`,
    verifyPasswordResetCode: (code) =>
      `${basePath}/verify-password-reset-code/${code}`,
    passwordReset: `${basePath}/password-reset`,
    changePassword: `${basePath}/change-password`,
    contact: `${basePath}/contact`,
    mailList: {
      join: `${basePath}/mail-list/join`,
      leave: `${basePath}/mail-list/leave`,
    },
    cart: {
      base: `${basePath}/cart`,
      single: (id) => `${basePath}/cart/${id}`,
    },
    orders: {
      base: `${basePath}/orders`,
      single: (code) => `${basePath}/orders/${code}`,
      buy: `${basePath}/orders/buy`,
    },
    lotteries: {
      base: `${basePath}/lotteries`,
    },
    shortUrl: {
      base: `${basePath}/short-urls`,
      single: (uuid) => `${basePath}/short-urls/${uuid}`,
    },
    systemConfig: {
      craueConfig: {
        single: (name) => `${basePath}/craue-configuration/${name}`,
        socialNetworks: `${basePath}/craue-configuration/social-networks`,
        whatsapp: `${basePath}/craue-configuration/whatsapp`,
        cryptapi: `${basePath}/craue-configuration/cryptapi`,
        banks: `${basePath}/craue-configuration/banks`,
        policiesAndTerms: `${basePath}/craue-configuration/policies-terms`,
        welcomePage: `${basePath}/craue-configuration/welcome-page`,
        welcomePageHTML: `${basePath}/craue-configuration/welcome-page-html`,
        creditCardPaymentClient: `${basePath}/craue-configuration/credit-card-payment-client`,
        creditCardPaymentClientLinks: `${basePath}/craue-configuration/credit-card-payment-client-links`,
      },
    },
    transactions: {
      base: `${basePath}/transactions`,
      single: (code) => `${basePath}/transactions/${code}`,
      token: `${basePath}/transaction-token`,
      skyChain: `${basePath}/transactions/deposit/sky-chain-link`,
    },
    files: {
      base: `${basePath}/file`?.replace("api/", ""),
      single: (file) => `${basePath}/file/${file}`?.replace("api/", ""),
    },
    balanceMovements: {
      base: `${basePath}/balance-movements`,
    },
    bankAccounts: {
      base: `${basePath}/user-bank-accounts`,
      single: (id) => `${basePath}/user-bank-accounts/${id}`,
    },
  },
};
