<template>
  <div>
    <span
      class="backdrop"
      v-if="!layout.collapsed"
      :style="{
        position: 'fixed',
        float: 'left',
        zIndex: 999,
        height: '100vh',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100vw',
        backgroundColor: 'rgba(0,0,0,0.5)',
        paddingTop: 0,
        transition: 'all 0.3s ease-in-out',
      }"
      @click="() => onCollapse(true)"
    />
    <a-layout-sider
      breakpoint="lg"
      :width="256"
      :collapsed="layout.collapsed"
      :trigger="null"
      :collapsible="true"
      :collapsed-width="'0px'"
      @breakpoint="onBreakPoint"
      class="sidebar-container"
      :style="{
        position: 'fixed',
        float: 'left',
        zIndex: 999,
        height: '100vh',
        left: 0,
        paddingTop: 0,
      }"
    >
      <div class="sidebar-container-logo">
        <a-button
          class="btn-close"
          @click="() => onCollapse(true)"
          shape="circle"
          icon="close"
        />
        <router-link
          :to="{ name: 'home' }"
          :style="{ display: 'flex', justifyContent: 'center' }"
        >
          <img
            alt="Logo"
            :src="banner"
            :style="{
              width: '170px',
              display: 'inline-block',
              overflow: 'hidden',
            }"
            @click="() => onCollapse(true)"
          />
        </router-link>
      </div>
      <perfect-scrollbar>
        <div
          class="scroll-bar"
          :style="{
            maxHeight: 'CALC(100vh - 88.94px)',
            paddingBottom: 25,
          }"
        >
          <a-menu
            theme="dark"
            mode="inline"
            @openChange="onOpenChange"
            @select="onSelectMenuChange"
            :openKeys="layout.openKeys.filter((key) => !!key) || []"
            :selectedKeys="layout.selectedKeys || []"
            :style="{
              borderRight: 0,
              height: 'calc(100% - 16px)',
              background: 'transparent',
            }"
            :subMenuCloseDelay="0.1"
            :subMenuOpenDelay="0.1"
          >
            <template v-for="menu in menus">
              <a-sub-menu
                v-if="menu.children && menu.children.length"
                :key="menu.key"
              >
                <span slot="title">
                  <component
                    v-if="menu.icon"
                    :is="menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                    :icon="menu.icon"
                    :type="menu.icon"
                    :class="menu.isFontAwesome && 'anticon'"
                  />
                  <span>{{ menu.title }}</span>
                </span>
                <a-menu-item
                  v-for="submenu in menu.children"
                  :key="submenu.key"
                  @click="() => onCollapse(true)"
                >
                  <component
                    v-if="submenu.icon"
                    :is="submenu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                    :icon="submenu.icon"
                    :type="submenu.icon"
                    :class="submenu.isFontAwesome && 'anticon'"
                  />
                  <span>{{ submenu.title }}</span>
                  <router-link v-if="submenu.key" :to="{ name: submenu.key }" />
                </a-menu-item>
              </a-sub-menu>

              <a-menu-item
                v-else
                @click="() => onCollapse(true)"
                :key="menu.key"
              >
                <component
                  v-if="menu.icon"
                  :is="menu.isFontAwesome ? 'font-awesome-icon' : 'a-icon'"
                  :icon="menu.icon"
                  :type="menu.icon"
                  :class="`${menu.isFontAwesome && 'anticon'} ${
                    menu.key === 'how-to-play' ? 'rotate' : ''
                  }`"
                />
                <span>{{ menu.title }}</span>
                <router-link v-if="menu.key" :to="{ name: menu.key }" />
              </a-menu-item>
            </template>
          </a-menu>
        </div>
      </perfect-scrollbar>
    </a-layout-sider>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import banner from "@/assets/images/admin/banner.png";
import { menus } from "@/router/client-menus.js";
export default {
  name: "SideBar",
  data() {
    return {
      banner,
    };
  },
  created() {
    this.onCollapse(true);
  },
  methods: {
    ...mapActions("layout", ["updateLayout"]),
    onOpenChange(openKeys) {
      const item = openKeys.pop();
      this.updateLayout({
        ...this.layout,
        openKeys: item ? [item] : [],
      });
    },
    onSelectMenuChange({ key }) {
      this.updateLayout({
        ...this.layout,
        selectedKeys: [key],
      });
    },
    onBreakPoint(broken) {
      this.updateLayout({
        ...this.layout,
        breakPoint: broken,
        collapsed: broken,
      });
    },
    onCollapse(collapsed = true) {
      this.updateLayout({
        ...this.layout,
        collapsed: collapsed,
      });
    },
  },
  computed: {
    ...mapGetters("layout", ["getLayoutDataStore"]),
    ...mapGetters("clientAuth", ["getLoggedUser"]),
    layout() {
      return this.getLayoutDataStore;
    },
    menus() {
      const { accessRoles = [] } = this.loggedUser || {};
      return menus(accessRoles)
        .filter((menu) => !menu?.hidden)
        .filter((menu) =>
          this.loggedUser ? !["login", "register"].includes(menu.key) : true
        );
    },
    loggedUser() {
      return this.getLoggedUser;
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
#app-layout {
  .sidebar-container {
    background-color: color(c-primary);
    z-index: 999;
    border-right: 1px solid color(--white-light);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.8 !important;
      background-color: color(c-primary) !important;
      z-index: -10;
    }
    &-logo {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.3s;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 18px 10px;
      text-align: center;
    }
    .btn-close {
      color: whitesmoke !important;
      position: absolute !important;
      z-index: 1 !important;
      top: 22px !important;
      right: 20px !important;
      background: transparent !important;
      border: none !important;
      transition: 0.5s ease-in-out;
      box-shadow: color(--white-light) 0px 0px 0px 2px !important;
      &:hover {
        background: rgba(152, 152, 152, 0.3) !important;
        transition: 0.5s ease-in-out;
      }
      @media screen and (max-width: $screen-mobile) {
        top: 15px !important;
        transition: 0.5s ease-in-out;
      }
    }
    .ant-menu-item {
      font-family: "Roboto", sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 0.875rem;
      color: color(--white) !important;
      .anticon {
        &.rotate {
          transform: rotate(90deg) !important;
        }
      }
      &-selected {
        border-right: 3px solid color(c-hover);
        transition: 0.5s;
        color: color(c-hover) !important;
        background-color: transparent !important;
        .anticon {
          color: color(c-hover) !important;
        }
      }
      &-active {
        border-right: 3px solid color(c-hover);
        transition: 0.5s;
        color: color(c-hover) !important;
        background-color: color(--white-light) !important;
        .anticon {
          color: color(c-hover) !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
</style>
